import React from "react"
import PropTypes from "prop-types"
import Nav from "./Nav"
import { Box, useColorMode } from "@chakra-ui/core"

import "./layout.css"

const Layout = ({ children }) => {
  const { colorMode } = useColorMode()
  const bgColor = { light: "white", dark: "white" }
  const color = { light: "black", dark: "black" }
  return (
      <Box
        style={{ backgroundColor: "white !important" }}
        bg={bgColor[colorMode]}
        color={color[colorMode]}
      >
        <Nav />
        <main className="main">{children}</main>
      </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
