import React from "react"
import { Link } from "gatsby"
import { Heading, Flex, Text } from "@chakra-ui/core"
import "./nav.css"

const activeStyle = { borderBottom: "1px solid lightgrey" }

const Nav = () => (
  <nav className="nav">
    <Flex justify="space-between">
      <Link to="/">
        <Heading size="md" letterSpacing="3px">
          Serey
        </Heading>
      </Link>
      <ul className="nav-list">
        <li className="nav-list-item">
          <Text>
            <Link activeStyle={activeStyle} to="/">
              Home
            </Link>
          </Text>
        </li>
        <li className="nav-list-item" style={{ marginRight: 0 }}>
          <Text>
            <Link activeStyle={activeStyle} to="/blog">
              Blog
            </Link>
          </Text>
        </li>
      </ul>
    </Flex>
  </nav>
)

export default Nav
